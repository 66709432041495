const WbPo = window.WbPo || {};

function sbgFormatMoney(number, places, symbol, thousand, decimal) {
	/* eslint-disable */
	number = number || 0;
	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : '$';
	thousand = thousand || ',';
	decimal = decimal || '.';

	var negative = number < 0 ? '-' : '',
		i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + '',
		j = 0;

	if (i.length > 3) {
		j = i.length % 3;
	}

	return symbol + negative + (
		j ? i.substr(0, j) + thousand : ''
	) + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (
			places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : ''
		);
	/* eslint-enable */
}

function wbpoFormatPrice(price) {
	let priceHtml = '<span class="woocommerce-Price-amount amount">';
	const priceFormatted = sbgFormatMoney(
		price,
		WbPo.price_decimals,
		'',
		WbPo.price_thousand_separator,
		WbPo.price_decimal_separator,
	);

	switch (WbPo.price_format) {
		case '%1$s%2$s':
			// left.
			priceHtml += `<span class="woocommerce-Price-currencySymbol"> ${WbPo.currency_symbol}</span> ${priceFormatted}`;
			break;
		case '%1$s %2$s':
			// Left with space
			priceHtml += `<span class="woocommerce-Price-currencySymbol"> ${WbPo.currency_symbol}</span> ${priceFormatted}`;
			break;
		case '%2$s%1$s':
			// right
			priceHtml += ` ${priceFormatted}<span class="woocommerce-Price-currencySymbol">${WbPo.currency_symbol}</span>`;
			break;
		case '%2$s %1$s':
			// right with space
			priceHtml += `${priceFormatted}<span class="woocommerce-Price-currencySymbol">${WbPo.currency_symbol}</span>`;
			break;
		default:
			// default
			priceHtml += `<span class="woocommerce-Price-currencySymbol"> ${WbPo.currency_symbol}</span> ${priceFormatted}`;
	}

	priceHtml += '</span>';

	return priceHtml;
}

function wbpoCalcPrice(wrap) {
	let total = 0;
	const products = wrap.querySelector('.wbpo-products');
	const totalWrapper = wrap.querySelector('.wbpo-total');

	products.querySelectorAll('.wbpo-product').forEach((product) => {
		if (product.dataset.price > 0 && product.dataset.qty > 0) {
			total += product.dataset.price * product.dataset.qty;
		}
	});

	const totalHtml = wbpoFormatPrice(total);
	totalWrapper.innerHTML = `${WbPo.total_text} : ${totalHtml}`;
	totalWrapper.classList.add('slide-down');

	if (WbPo.change_price === 'on') {
		// change the main price.
		let priceSelector = '.summary > .price';

		if (WbPo.price_selector !== null && WbPo.price_selector !== '') {
			priceSelector = WbPo.price_selector;
		}

		if (wrap.querySelector(priceSelector) && total > 0 ) {
			wrap.querySelector(priceSelector).innerHTML = totalHtml;
		}
	}

	const event = new Event('wbpo_calc_price');

	document.body.dispatchEvent(event, [total, totalHtml]);
}

function wbpoSaveIds(wrap) {
	const ids = [];
	const products = wrap.querySelector('.wbpo-products');
	const currentGroup = wrap.querySelector('.wbpo-current-group');

	products.querySelectorAll('.wbpo-product').forEach((product) => {
		if (product.dataset.id > 0 && product.dataset.qty > 0) {
			ids.push({ id: product.dataset.id, qty: product.dataset.qty });
		}
	});

	currentGroup.value = JSON.stringify(ids);
}

function wbpoDecimalPlaces(num) {
	/* eslint-disable */
	var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

	if (!match) {
		return 0;
	}

	return Math.max(
		0,
		// Number of digits right of decimal point.
		(match[1] ? match[1].length : 0)
		// Adjust for scientific notation.
		- (match[2] ? +match[2] : 0));
	/* eslint-enable */
}

function wbpoInit(wrap) {
	let isSelection = false;
	let isEmpty = true;
	const products = wrap.querySelector('.wbpo-products');
	const btn = wrap.querySelector('.single_add_to_cart_button');

	products.querySelectorAll('.wbpo-product').forEach((product) => {
		if (product.dataset.qty > 0 && parseInt(product.dataset.id, 10) === 0) {
			isSelection = true;
		}
		if (product.dataset.qty > 0) {
			isEmpty = false;
		}
	});

	if (isSelection || isEmpty) {
		btn.classList.add('wbpo-disabled');
		btn.setAttribute('disabled', true);
		if (isSelection) {
			btn.classList.add('wbpo-selection');
		} else {
			btn.classList.remove('wbpo-selection');
		}
		if (isEmpty) {
			btn.classList.add('wbpo-empty');
		} else {
			btn.classList.remove('wbpo-empty');
		}
	} else {
		btn.classList.remove('wbpo-disabled', 'wbpo-selection', 'wbpo-empty');
		btn.disabled = false;
	}

	wbpoCalcPrice(wrap);
	wbpoSaveIds(wrap);
}

function productSelectionChecked(product) {
	const { selectionType } = product.dataset;
	if (selectionType === 'radio') {
		product.addEventListener('click', (e) => {
			document.querySelectorAll('.wbpo-product').forEach((_product) => {
				_product.classList.remove('checked');
				_product.querySelector('input[type="radio"]').checked = false;
			});
			e.target.closest('.wbpo-product').classList.add('checked');
			e.target.closest('.wbpo-product').querySelector('input[type="radio"]').checked = true;
			e.target
				.closest('.wbpo-product')
				.querySelector('input[type="radio"]')
				.dispatchEvent(new Event('change'));
		});
	}
	if (selectionType === 'checkbox') {
		product.addEventListener('click', (e) => {
			// eslint-disable-next-line @wordpress/no-unused-vars-before-return
			const checkBox = e.target
				.closest('.wbpo-product')
				.querySelector('input[type="checkbox"]');

			if (
				e.target.closest('.wbpo-products').classList.contains('classic') &&
				e.target === e.target.closest('.checkmark')
			) {
				checkBox.dispatchEvent(new Event('change'));
				return;
			}
			if (e.target.dataset.attribute_name) {
				e.preventDefault();
				return;
			}
			if (e.target.closest('.wbpo-product').classList.contains('checked')) {
				checkBox.checked = false;
			} else {
				checkBox.checked = true;
			}
			checkBox.dispatchEvent(new Event('change'));
		});
	}
}

// eslint-disable-next-line @wordpress/no-global-event-listener
document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.product-type-wbpo').forEach((wrap) => {
		if (wrap.querySelectorAll('.wbpo-product').length) {
			wbpoInit(wrap);
			wrap.querySelectorAll('.wbpo-product').forEach((product) => {
				productSelectionChecked(product);
			});
		}
	});

	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener('wbpo_found_variation', (e) => {
		const wrap = e.originalTarget.closest('.product-type-wbpo');
		const { data } = e;
		const product = e.originalTarget.closest('.wbpo-product');
		if (product) {
			// if (
			// 	document.querySelector(
			// 		'.wbpo-products.classic .wbpo-product .variations select option:first-child',
			// 	)
			// ) {
			// 	document
			// 		.querySelectorAll(
			// 			'.wbpo-products.classic .wbpo-product .variations select option:first-child',
			// 		)
			// 		.forEach((item) => {
			// 			//item.remove();
			// 			item.disabled = true;
			// 		});
			// }
			if (data.image.url && data.image.srcset) {
				// change image
				const thumbImage = product.querySelector('.wbpo-thumb img');
				if (thumbImage) {
					thumbImage.setAttribute('srcset', data.image.srcset);
					thumbImage.setAttribute('src', data.image.src);
				}
			}

			if (data.price_html && product.querySelector('.wbpo-price')) {
				// change price.
				product.querySelector('.wbpo-price').innerHTML = data.price_html;
			}

			if (data.is_purchasable) {
				// change stock notice
				if (data.is_in_stock) {
					product.dataset.id = data.variation_id;
					product.dataset.price = data.display_price;
				} else {
					product.setAttribute('data-id', 0);
					product.setAttribute('data-price', 0);
				}

				// Change availability text.
				if (data.availability_html && data.availability_html !== '') {
					product.querySelector('.wbpo-availability').innerHTML = data.availability_html;
					product.querySelector('.wbpo-availability').style.display = 'block';
				} else {
					product.querySelector('.wbpo-availability').innerHTML = '';
					product.querySelector('.wbpo-availability').style.display = 'hide';
				}
			}

			if (
				data.variation_description !== '' &&
				product.querySelector('.wbpo-variation-description')
			) {
				product.querySelector('.wbpo-variation-description').innerHTML =
					data.variation_description;
				product.querySelector('.wbpo-variation-description').style.display = 'block';
			} else if (product.querySelector('.wbpo-variation-description')) {
				product.querySelector('.wbpo-variation-description').innerHTML =
					data.variation_description;
				product.querySelector('.wbpo-variation-description').style.display = 'hide';
			}

			if (WbPo.change_image === 'off') {
				// prevent changing the main image.
				e.originalTarget
					.closest('.variations_form')
					.dispatchEvent(new Event('reset_image'));
			}

			wbpoInit(wrap);
		}
	});

	// eslint-disable-next-line @wordpress/no-global-event-listener
	document.addEventListener('wbpo_reset_data', (e) => {
		const wrap = e.originalTarget.closest('.product-type-wbpo');
		const product = e.originalTarget.closest('.wbpo-product');

		if (product) {
			// Reset image.
			// eslint-disable-next-line no-undef
			if (product.querySelector('.wbpo-thumb img')) {
				product.querySelector('.wbpo-thumb img').src =
					product.querySelector('.wbpo-thumb').dataset.thumb;
				product.querySelector('.wbpo-thumb img').srcset = '';
			}

			// reset price
			if (product.querySelector('.wbpo-price')) {
				product.querySelector('.wbpo-price').innerHTML =
					product.querySelector('.wbpo-price').dataset.priceHtml;
			}

			// reset availability
			if (product.querySelector('.wbpo-availability')) {
				product.querySelector('.wbpo-availability').innerHTML = '';
				product.querySelector('.wbpo-availability').style.display = 'hide';
			}

			// reset desc
			if (product.querySelector('.wbpo-variation-description')) {
				product.querySelector('.wbpo-variation-description').innerHTML = '';
				product.querySelector('.wbpo-variation-description').style.display = 'hide';
			}

			// reset data
			product.setAttribute('data-id', 0);
			product.setAttribute('data-price', 0);

			wbpoInit(wrap);
		}
	});
	document.querySelector('.single_add_to_cart_button').addEventListener('click', (e) => {
		if (e.target.classList.contains('wbpo-disabled')) {
			if (e.target.classList.contains('wbpo-selection')) {
				// eslint-disable-next-line no-alert
				alert(WbPo.alert_selection);
			} else if (e.target.classList.contains('wbpo-empty')) {
				// eslint-disable-next-line no-alert
				alert(WbPo.alert_empty);
			}
			e.preventDefault();
		}
	});

	document.querySelectorAll('.wbpo-qty-mod').forEach((btn) => {
		btn.addEventListener('click', (e) => {
			const qtyWrapper = e.target.parentElement;
			const qty = qtyWrapper.querySelector('.qty');
			let val = qty.value || 0;
			let min = qty.attributes.min.value || 0;
			let max = qty.attributes.max.value || 0;
			let step = qty.attributes.step.value || 1;

			val = parseFloat(val);
			min = parseFloat(min);
			max = parseFloat(max);
			step = parseFloat(step);

			if (e.target.classList.contains('wbpo-qty-plus')) {
				if (max > 0 && val === max) {
					qty.value = val;
				} else {
					qty.value = (val + step).toFixed(wbpoDecimalPlaces(step));
				}
			} else if (val === min || val < min) {
				qty.value = min;
			} else if (val > 0) {
				qty.value = (val - step).toFixed(wbpoDecimalPlaces(step));
			}

			qty.dispatchEvent(new Event('change'));
		});
	});

	if (document.querySelector('.qty')) {
		document.querySelectorAll('.qty').forEach((input) => {
			input.addEventListener('change', (e) => {
				const qtyWrapper = e.target.parentElement;
				const wrap = e.target.closest('.product-type-wbpo');
				const qtyInput = e.target;
				let qty = qtyInput.value || 0;
				let min = qtyWrapper.dataset.min || 0;
				let max = qtyWrapper.dataset.min || 0;

				qty = parseFloat(qty);
				min = parseFloat(min);
				max = parseFloat(max);

				if (qty > 0 && qty < min) {
					qty = min;
					qtyInput.value = qty;
				}

				if (max > 0 && qty > max) {
					qty = max;
					qtyInput.value = qty;
				}

				e.target.closest('.wbpo-product').setAttribute('data-qty', qty);

				wbpoInit(wrap);
			});
		});
	}

	document.querySelectorAll('.wbpo-checkbox').forEach((checkbox) => {
		checkbox.addEventListener('change', (e) => {
			const checkBox = e.target;
			const wrap = checkBox.closest('.product-type-wbpo');
			if (checkBox.checked) {
				checkBox.closest('.wbpo-product').setAttribute('data-qty', 1);
				checkBox.closest('.wbpo-product').classList.add('checked');
			} else {
				checkBox.closest('.wbpo-product').setAttribute('data-qty', 0);
				checkBox.closest('.wbpo-product').classList.remove('checked');
			}
			wbpoInit(wrap);
		});
	});

	document.querySelectorAll('.wbpo-radio').forEach((radioBtn) => {
		radioBtn.addEventListener('change', (e) => {
			const radioBtn = e.target;
			const wrap = radioBtn.closest('.product-type-wbpo');
			if (radioBtn.checked) {
				radioBtn.closest('.wbpo-product').setAttribute('data-qty', 1);
			}
			document.querySelectorAll('.wbpo-radio').forEach((_radioBtn) => {
				if (e.target !== _radioBtn) {
					_radioBtn.closest('.wbpo-product').setAttribute('data-qty', 0);
					_radioBtn.checked = false;
				}
			});
			wbpoInit(wrap);
		});
	});
});

// Converting jquery WooCommerce Event listener into Vanilla JS.

// eslint-disable-next-line no-undef
jQuery(document).on('found_variation', (e, data) => {
	const event = new Event('wbpo_found_variation');
	event.data = data;
	event.originalTarget = e.target;
	document.dispatchEvent(event);
});

// eslint-disable-next-line no-undef
jQuery(document).on('reset_data', (e) => {
	const event = new Event('wbpo_reset_data');
	event.originalTarget = e.target;
	document.dispatchEvent(event);
});
